import $ from 'jquery'

const scrollToTarget = (targetId) => {
	const $target = $(targetId)
	const offset = $target.offset().top - 280

	$('html, body')
		.stop()
		.animate(
			{
				scrollTop: offset,
			},
			400,
			'swing',
			function () {},
		)
}

$(document).ready(function () {
	if (window.location.hash) {
		scrollToTarget(window.location.hash)
	}

	$('[data-smoothscroll-to]').on('click', function (e) {
		var target = $(this).data('smoothscroll-to')
		scrollToTarget('#' + target)
	})

	// platform walkthrough
	$('.video-btn').on('click',function() {
    const videoSrc = $(this).data("src");
		const videoTitle = $(this).data("title");

		$('#videoTitle').html(videoTitle)
		$('#video source').attr('src', videoSrc)
		$('#video')[0].load();
	});

	// new pricing scripts
	$('body').on('hide.bs.modal', '.modal', function () {
		$('video').trigger('pause')
	})
	$('body').on('show.bs.modal', '.modal', function () {
		$('video').trigger('play')
	})

})
